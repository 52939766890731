
import moment from "moment";

import Property from "./components/Property.vue";
import Activity from "./components/Activity.vue";
import TableCellDownloadStatus from "./components/TableCellDownloadStatus.vue";
import Overlay from "./explore/components/Overlay/Overlay.vue";

import MeAuth from "~/graphql/Authentication/Me.gql";
import Lists from "~/graphql/Lists/Lists.gql";
import Properties from "~/graphql/Home/Properties.gql";
import DocumentCollections from "~/graphql/Home/DocumentCollections.gql";

import { useUserStore } from "~/composables/stores/user";

import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPlots,
  getPropertyType,
  getPropertyIcon,
  getPropertyAddress,
  getPropertyTypeText,
} from "~/helpers/property-helpers.js";

export default {
  components: {
    Overlay,
    Property,
    Activity,
    TableCellDownloadStatus,
  },

  apollo: {
    me: { query: MeAuth },

    lists: { query: Lists },

    documentCollections: {
      query: DocumentCollections,
      fetchPolicy: "network-only",
    },

    propertiesByBFENumbers: {
      query: Properties,
      variables: function () {
        return {
          bfeNumbers: this.getBfeNumbersForLastVisitedProperties(),
        };
      },
    },
  },

  computed: {
    userName() {
      return useUserStore().user?.name;
    },

    propertiesLoading() {
      return this.$apollo.queries.propertiesByBFENumbers.loading;
    },

    listsLoading() {
      return this.$apollo.queries.lists.loading;
    },

    documentsLoading() {
      return this.$apollo.queries.documentCollections.loading;
    },

    recentUpdatedLists() {
      const listsSortedByUpdatedAt = [...this.lists].sort((a, b) => {
        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      });
      return listsSortedByUpdatedAt.slice(0, 5);
    },

    recentDocumentCollections() {
      if (this.documentsLoading || !this.documentCollections) {
        return [];
      }

      const documentCollectionsSortedByUpdatedAt = [...this.documentCollections].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      return documentCollectionsSortedByUpdatedAt.slice(0, 5);
    },

    recentVisitedProperties() {
      return this.propertiesByBFENumbers.map((property) => {
        return {
          owner: property.owners ? property.owners[0] : null,
          numAdditionalOwners: property.owners ? property.owners.length - 1 : 0,
          bfeNumber: property.bfeNumber,
          type: getPropertyType(property),
          icon: getPropertyIcon(property),
          address: getPropertyAddress(property),
          typeText: getPropertyTypeText(property),
          keyValues: this.getKeyValuePairsForProperty(property),
        };
      });
    },
  },
  watch: {
    me: function (val) {
      if (val) {
        useUserStore().user = {
          id: val.id,
          name: val.name,
          email: val.email,
          orgId: val.organization.id,
          orgName: val.organization.name,
        };
      }
    },
  },

  methods: {
    goToExplore() {
      this.$router.push("explore");
    },

    goToPropertyOwners(property) {
      this.$router.push({
        name: "explore-property-id-owners",
        params: { id: property.bfeNumber },
      });
    },

    goToList(list) {
      this.$router.push({
        name: "my-estaid-lists-id",
        params: { id: list.id },
      });
    },

    downloadDocumentCollection(documentCollection) {
      this.$router.push({
        name: "download-packaged-id-filename",
        params: {
          id: documentCollection.id,
          filename: documentCollection.filename,
        },
      });
    },

    getBfeNumbersForLastVisitedProperties() {
      const recentProperties = this.$store.getters["pagevisit/lastVisitedProperties"];

      return [...recentProperties]
        .sort((a, b) => new moment(b.timestamp) - new moment(a.timestamp))
        .slice(0, 3)
        .map((p) => parseInt(p.bfeNumber, 10));
    },

    getKeyValuePairsForProperty(property) {
      let keyValues = [];

      keyValues.push({
        key: "Bfe nummer",
        value: property.bfeNumber,
      });

      switch (getPropertyType(property)) {
        case PROPERTY_TYPE_MAIN_PROPERTY:
        case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
          keyValues.push({
            key: "Matrikel",
            value: getPlots(property)[0].ownersGuildName + ", " + getPlots(property)[0].matrikelNumber,
          });
          break;

        case PROPERTY_TYPE_CONDO:
          break;

        case PROPERTY_TYPE_UNKNOWN:
          break;
      }

      return keyValues;
    },

    hasDownload(documentCollection) {
      return documentCollection.status == "COMPLETED" || documentCollection.status == "PARTIAL";
    },
  },
};
